<template>
<nav class="acd-panel text-center">
    <h2>Agency Care Desk</h2>
    <span class="acd-text"> <span style="color:#000000">Text: </span> {{this.textPhoneNumber}}
    </span>
    <span class="separator d-none d-md-inline">|</span>
    <a class="email link" href="mailto:agencycare@safelite.com">agencycare@safelite.com</a>
    <span class="separator d-none d-md-inline">|</span>
    <button class="faq link" @click="checkUnauth">FAQs</button>
    <span class="separator d-none" :class="[shouldShowChat ? 'd-md-inline' : 'd-md-none']">|</span>
    <span class="chat-now" :class="{ 'd-none': !shouldShowChat}"><a class="chat-now" @click="chatEmit('cta')">CHAT NOW</a>
        <figure class="chat-icon-container"><img class="chat-icon" @click="chatEmit('icon')" src="@/assets/icons/icon-chat.png" /></figure>
    </span>
    <div>
    <ModalWithButtons primaryButtonText="Yes, leave" secondaryButtonText="No, stay here" :openModal="isCancelModalOpen" @closing="showCancelModal(false, 'close')" @secondary-button-clicked="showCancelModal(false)" @primary-button-clicked="onCancelContinueClicked">
        <h4 class="cancel-title title">Are you sure you want to leave this page?</h4>
        <p class="cancel-content pb-6 pb-7">If you leave now, you won't be able to finish registering for this course unless you click the link again in the email sent to you.</p>
    </ModalWithButtons>
    </div>
</nav>



</template>

<script>
import { globalValueConsts, baseConsts } from "@/constants.js";
import ModalWithButtons from "@/sharedcomponents/ModalWithButtons.vue";
import { routeNameConstants } from '../../constants';

export default {
    name: "AgencyCareDeskPanel",
    props: {
        isChatEnabled: Boolean,
        pageName: String,
        isSafeliteAgent: String,
        isLoggedin: Boolean
        
    },
    emits: ['chat-button-clicked'],

    data() {
        return {
            isCancelModalOpen: false,
        }
    },
     components: {
        ModalWithButtons,
    },
    computed: {
        shouldShowChat() {
            return this.isChatEnabled;
        },
        // Will be used once the number is set up to handle texts
        textPhoneNumber() {
            return globalValueConsts.TEXT_PHONE_NUMBER
        },
        faqLink() {
            return baseConsts.SFA_FAQ_LINK
        },
       
    },
    methods: {
        showAreYouSureModal() {
            var courseID = sessionStorage.getItem("courseID");
            if (courseID != null) {
                if(this.$route.name==routeNameConstants.COURSE_REGISTRATION_UNAUTH 
                    || this.$route.name==routeNameConstants.SELECT_AGENCY_PAGE
                    ||this.$route.name==routeNameConstants.FIND_AGENCY_PAGE
                    || this.$route.name == routeNameConstants.AGENT_REGISTRATION
                    || this.$route.name == routeNameConstants.LOGIN_SCREEN) {
                        return true;
                    }
                }
            else {
                return false;
            }
        },
        showCancelModal(shouldShowCancelModal, btnLabel = null) {
            this.isCancelModalOpen = shouldShowCancelModal;
        },
        checkUnauth: function () {
            ;
            var self = this;

            if(this.showAreYouSureModal() == true)
            {
                this.showCancelModal(true);
            } else {
                
              window.location=baseConsts.SFA_FAQ_LINK;
            }
        },
        onCancelContinueClicked() {
           var self = this;
           sessionStorage.removeItem("courseID");
           sessionStorage.removeItem("OktaID");
           window.location=baseConsts.SFA_FAQ_LINK;
        },
        
        chatEmit() {
            var gaCategory = "agent_chat"
            if (this.isSafeliteAgent == "SFAD_Agent") {
                gaCategory = "agent_dashboard"
            } else if (this.isSafeliteAgent == "SFAD_Not_Agent") {
                gaCategory = "agent_admin_dashboard"
            }
            this.$emit('chat-button-clicked');
            this.pushEventToGoogleDataLayer(gaCategory, "footer_chat_clicked", this.pageName, 0);
        }
    }

}
</script>

<style lang="scss" scoped>
.acd-panel {
    background-color: $grayscale-seven;
    border-bottom: 1px solid #d4d6d8;
    width: 100%;

    .h2 {
        font-size: 26px;
        line-height: 35px;
    }
    .title{
        text-align: left;
    }
    .pb-7{
        text-align: left;
    }

    .email {
        font-weight: $font-weight-bold;
    }

    .faq {
        font-weight: $font-weight-bold;
    }

    .separator {
        margin: 0 $spacer-four;
    }

    @include tablet {
        padding: 40px $page-padding 45px;

        h2 {
            padding-bottom: $spacer-five;
        }
    }
    .acd-text {
   padding-top: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.75px;
}
    @include wide-mobile {
        padding: 45px $page-padding;
         .faq {
        width: 345px;
    }
        h2 {
            padding-bottom: 25px;
        }

        &>* {
            display: block;
            padding-bottom: 25px;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
</style>
