//////////////////////////////////////////////////////////////////////////////////////
// Our compiled router that will be deployed. Note that routes are built            //
// dynamically for the Content Hub. We are not importing Content routes here        //
// from our routes.js but rather using Mustache.js to compile them from Sitefinity. //
/////////////////////////////////////////////////////////////////////////////////////

import { routeNameConstants } from "@/constants.js";
import { lazyLoadContentHubLayout } from "./router-helper.js";

export const contentSiteRoutes = [
    {
        path: '/articles/auto-glass-repair',
        name: 'Auto glass repair - Three gifts in one service',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/scheduling-recalibration',
        name: 'Scheduling Recalibration',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/windshield-complexity',
        name: 'Windshield complexity',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/competition-showcases-safelite-s-top-techs',
        name: 'Competition showcases Safelite’s top techs',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/route-test-page',
        name: 'Route test page',
        component: lazyLoadContentHubLayout('HomePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/online-agent-claims-tools',
        name: 'Online agent claims tools',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/national-coverage',
        name: 'National coverage',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/honoring-national-safety-month',
        name: 'Honoring National Safety Month',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/tech-future',
        name: 'The insurance agents role in the future of vehicle safety systems',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/why-do-wiper-blades-matter',
        name: 'Why do wiper blades matter',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/oneanddonewindshieldreplacement',
        name: 'One-and-done replacement and recalibration',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/oem',
        name: 'The ABCs of OEM and OE',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/continuing-education-for-agents',
        name: 'Continuing Education for Agents',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/sfa',
        name: 'Your new agent website is open for business',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/understand-recalibration',
        name: 'Help your policyholder understand recalibration',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/agency-care-desk',
        name: 'Agency Care Desk',
        component: lazyLoadContentHubLayout('AgentHelpDeskHomeLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/celebrating-75-years',
        name: 'Celebrating 75 years of customer-centric service',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/nationwide-auto-glass-service',
        name: 'Nationwide auto glass service',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/windshield-scams',
        name: 'Help your policyholders avoid windshield scams',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/answers-you-need-when-you-need-them',
        name: 'Answers you need, when you need them',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/claims-best-practices',
        name: 'Delivering the best policyholder experience',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/understand-how-advanced-safety-systems-affect-recalibration-needs',
        name: 'Understand  how advanced safety systems affect recalibration needs',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/repairs-save-policyholders-cash',
        name: 'repairs save policyholders cash',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/a-day-in-the-life',
        name: 'A day In the life of a Safelite technician',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/earn-ce-credit',
        name: 'Earn CE credit with courses that make the grade',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/catastrophes',
        name: 'Ready when and how you need us',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/recalibration-is-key-to-safety',
        name: 'Educating your policyholder on recalibration',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/the-rising-cost-of-windshield-replacement',
        name: 'The rising cost of windshield replacement',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/continuing-ed-made-simple',
        name: 'Continuing Ed made simple',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/home',
        name: 'Home',
        component: lazyLoadContentHubLayout('HomePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/ce-courses',
        name: 'Have fun, learn stuff, earn continuing ed credits',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/smarter-part-selection',
        name: 'Smarter Part Selection',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/florida-law-curbs-windshield-scams',
        name: 'Florida law curbs windshield scams',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/agent-care-desk',
        name: 'Agent Care Desk',
        component: lazyLoadContentHubLayout('AgentHelpDeskHomeLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/windshield-recycling',
        name: 'Leaders in windshield recycling',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/agenthelpdesk',
        name: 'AgentHelpDesk',
        component: lazyLoadContentHubLayout('AgentHelpDeskHomeLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/drop-and-go',
        name: 'Drop and Go',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/drop-and-go',
        name: 'Coming Soon Drop and Go',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/tagged-articles',
        name: 'Tagged Articles',
        component: lazyLoadContentHubLayout('TaggedArticleDisplayListLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/better-customer-experiences',
        name: 'Creating even better customer experiences',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/safelite-products',
        name: 'Adding even more value to your policyholder relationship',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/safelite-uses-ai-to-simplify-part-selection',
        name: 'Safelite uses AI to simplify part selection',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/testing',
        name: 'Testing',
        component: lazyLoadContentHubLayout('LandingPageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/the-year-2022-in-review',
        name: 'The year 2022 in review',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/no-chips-cracks-or-streaks',
        name: 'No chips, cracks or streaks',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/search',
        name: 'Search',
        component: lazyLoadContentHubLayout('SearchResultsLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/acd-new-page',
        name: 'AcdNewPage',
        component: lazyLoadContentHubLayout('ContentHubMainLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/global-supply-chain',
        name: 'Global supply chain',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/earn-ce-credit-with-courses-that-make-the-grade',
        name: 'Earn CE credit with course that make the grade',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/easier-claim-management',
        name: 'Easier claim management',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/choose-safelite',
        name: 'Choose Safelite',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/is-oem-part-of-your-policyholder-s-coverage',
        name: 'Is OEM part of your policyholder’s coverage',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/lead-gen',
        name: 'Let the policyholder experience drive your business forward',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/safelites-brand-evolution-part-2',
        name: 'Safelites brand evolution Part 2',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/safelite-leadership-spotlight-renee-cacchillo',
        name: 'Safelite leadership spotlight Renee Cacchillo',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/your-q-and-a-for-safelite-for-agents',
        name: 'Your Q and A for Safelite for Agents',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/our-commitment-to-you-in-the-coming-year',
        name: 'Our commitment to you in the coming year',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/tesla-glass-replacement-and-recalibration',
        name: 'We’re the clear choice for Tesla glass replacement and recalibration',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/choosing-the-best-glass',
        name: 'Choosing the Best Glass',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/safelites-brand-evolution',
        name: 'Safelite brand evolution',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/trust-the-safety-and-reliability-of-safelite',
        name: 'Trust the safety and reliability of Safelite',
        component: lazyLoadContentHubLayout('ArticlePageWideLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/course-registration',
        name: 'CourseRegistration',
        component: lazyLoadContentHubLayout('EducationRegistrationLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/drop-and-go-is-here',
        name: 'Drop and Go Is Here',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/',
        name: routeNameConstants.CONTENT_SITE_HOME,
        component: lazyLoadContentHubLayout("HomePageLayout"),
        meta: { requiresAuth: false },
    },
]
