<template>
<div class="standard-footer-container">
    <div class="acd-footer-info">
        <AgencyCareDeskPanel class="acd-info" :isSafeliteAgent="isSafeliteAgent" :pageName="pageName" v-if="shouldShowACDPanel" :isChatEnabled="isChatEnabled" @chat-button-clicked="handleChatButtonClick" />
        <AreaManagerPanel class="acd-info" :isSafeliteAgent="isSafeliteAgent" :pageName="pageName" :isChatEnabled="isChatEnabled" v-else-if="shouldShowAreaManagerPanel" @chat-button-clicked="handleChatButtonClick" />
    </div>

    <ContentSiteFooter v-show="isContentHubPath" :tags="tags" />

    <div class="standard-footer">
        <nav class="general-nav sub-footer-nav">
            <ul>
                <li>&copy; {{ currentYear }} Safelite Group</li>

                <li v-for="(link, i) in subFooterNavLinks" :key="i">
                    <a :href="link.href" :target="link.target">{{ link.text }} <img v-if="link.image != null" :src="link.image" alt="" /></a>
                </li>
            </ul>
        </nav>
    </div>
</div>
</template>

<script>
import {
    baseConsts,
    routeNameConstants,
    globalEventConsts,
    OIDC
} from "@/constants.js";
import {
    EventBus
} from "@/event-bus.js";
import AreaManagerPanel from "./AreaManagerPanel.vue";
import AgencyCareDeskPanel from "./AgencyCareDeskPanel.vue";
import ContentSiteFooter from "@/components/ContentSite/ContentSiteFooter/ContentSiteFooter.vue";
import { useUserStore } from "@/stores/user.js"
import { useSfaStore } from "@/stores/sfa.js"

export default {
    name: "standard-footer",
    setup() {
        const userStore = useUserStore();
        const sfaStore = useSfaStore();
        return { userStore, sfaStore };
    },
    created() {
        this.UserName = this.authState?.idToken ? this.authState.idToken?.claims.name : "";
    },
    components: {
        AreaManagerPanel,
        AgencyCareDeskPanel,
        ContentSiteFooter
    },
    watch: {
        "authState.idToken": function (newIdToken) {
            this.isSafeliteAgent = newIdToken ? newIdToken?.claims.dashboardUserRole?.includes("SFAD_Agent") ? "SFAD_Agent" : "SFAD_Not_Agent" : "";
        }
    },
    props: {
        isContentHubPath: Boolean,
        tags: Object
    },
    data: function () {
        return {
            pageName: '',
            isSafeliteAgent: "",
            currentYear: new Date().getFullYear(),
            subFooterNavLinks: [{
                    text: "Terms of service",
                    href: `${baseConsts.TERMS_LINK}`,
                    target: "_blank",
                },
                {
                    text: "Your privacy choices",
                    href: baseConsts.PRIVACY_LINK,
                    image: require("@/assets/icons/icon-ccpa.png"),
                    target: "_blank",
                },
                {
                    text: "Cancellation policy",
                    href: `${baseConsts.SAFELITE_COM_BASE_URL}/cancellation-refund-policy`,
                    target: "_blank",
                },
                {
                    text: "Notice at collection",
                    href: `${baseConsts.SAFELITE_COM_BASE_URL}/ccpa-privacy-policy`,
                    target: "_blank",
                },
            ],

        };
    },
    methods: {
        handleChatButtonClick() {
            this.openWebChat(this.authState.idToken ? this.authState.idToken.claims : null);
        },
        openWebChat(Userinfo) {
            this.pushEventToGoogleDataLayer("web_chat", "chat_accept", "insurance_agent_started_chat", 0); 
            EventBus.$emit(globalEventConsts.OPEN_CHAT, Userinfo);
        },
    },
    computed: {
        shouldShowACDPanel() {
            const pagesWhereACDPanelIsShown = [
                routeNameConstants.LOGIN_SCREEN.toLowerCase(),
                routeNameConstants.AGENT_REGISTRATION.toLowerCase(),
                routeNameConstants.FIND_AGENCY_PAGE.toLowerCase(),
                routeNameConstants.AGENT_REGISTRATION.toLowerCase(),
                routeNameConstants.SELECT_AGENCY_PAGE.toLowerCase(),
                routeNameConstants.FORGOT_PASSWORD.toLowerCase(),
                routeNameConstants.CREATE_NEW_PASSWORD.toLowerCase(),
                routeNameConstants.AGENCY_NOT_FOUND_PAGE.toLowerCase(),
                routeNameConstants.AGENT_REGISTRATION_SUCCESSFUL.toLowerCase(),
                routeNameConstants.POLICY_HOLDER_QUOTE.toLowerCase(),
                routeNameConstants.FILE_CLAIM.toLowerCase(),
                routeNameConstants.START_CLAIM.toLowerCase(),
                routeNameConstants.MANAGE_CLAIM.toLowerCase(),
                routeNameConstants.CE_COURSES_HOME.toLowerCase(),
                routeNameConstants.NOT_FOUND_PAGE.toLowerCase(),
                !this.userStore?.userRole?.includes(OIDC.INTERNAL_ADMIN_ROLE) ? routeNameConstants.AGENCY_ADMIN_ADD.toLowerCase() : null,
                routeNameConstants.CONTENT_SITE_HOME.toLowerCase(),
                routeNameConstants.CONTENT_SITE_SEARCH_RESULTS.toLowerCase(),
                routeNameConstants.CONTENT_SITE_TAGGED_ARTICLES_LIST.toLowerCase(),
                this.userStore?.userRole?.includes(OIDC.INTERNAL_ADMIN_ROLE) ? routeNameConstants.AGENT_REGISTRATION_SUCCESSFUL.toLowerCase() : routeNameConstants.SUBMIT_CLAIM_REVIEW_DETAILS.toLowerCase(),
                routeNameConstants.APPOINTMENT_OPTION_DETAILS.toLowerCase(),
                routeNameConstants.SUCCESS_SUBMIT_CLAIM.toLowerCase(),
                this.userStore?.userRole?.includes(OIDC.INTERNAL_ADMIN_ROLE) ? routeNameConstants.AGENT_REGISTRATION_SUCCESSFUL.toLowerCase() : routeNameConstants.START_CLAIM.toLowerCase()
            ]
            this.pageName = this.$route?.name;
            return (this.$route?.name && pagesWhereACDPanelIsShown.indexOf(this.$route?.name.toLowerCase()) != -1) || (this.$route?.path && this.$route?.path.indexOf('/articles/') >=0)  ? true : false;
        },
        shouldShowAreaManagerPanel() {
            let isCEAdmin = this.authState?.idToken?.claims?.dashboardUserRole?.includes(OIDC.CE_ADMIN_ROLE);
            let isInternalAdmin = this.authState?.idToken?.claims?.dashboardUserRole?.includes(OIDC.INTERNAL_ADMIN_ROLE);
            return !isCEAdmin && !isInternalAdmin && !this.isContentHubPath 
                && this.pageName !== routeNameConstants.CONTENT_SITE_ARTICLE_DETAILS
                && this.pageName !== routeNameConstants.COURSE_REGISTRATION_UNAUTH
        },
        isChatEnabled(){
                return this.sfaStore?.webChatEnabled;
        }
    },
};
</script>

<style lang="scss">
.standard-footer-container {
    .acd-footer-info {
        background-color: $grayscale-seven;
        border-bottom: 1px solid #d4d6d8;

        .acd-info {
            .chat-now {
                padding-top: $spacer-three;
                font-size: 14px;
                color: $blue;
                font-weight: $font-weight-bold;
                line-height: 20px;
                letter-spacing: 0.75px;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }

                .chat-icon-container {
                    display: inline-flex;
                    background-color: $blue;
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    justify-content: center;
                    align-items: center;
                    margin-left: $spacer-three;
                    vertical-align: middle;

                    .chat-icon {
                        width: 17px;
                        height: 16px;
                        margin-left: 1px;
                    }
                }
            }
        }
    }

    .standard-footer {
        padding: 32px $page-padding;
        text-align: center;

        .sub-footer-nav {
            max-width: 1020px;
            margin: auto;

            ul {
                list-style: none;

                li,
                a {
                    @include disclaimer-text;
                    display: inline-block;
                    padding: 0 $spacer-one;
                }

                 img {
                        position: relative;
                        top: 4px;
                        width: 34px;
                        height: 16px;
                    }

                a {
                    margin: 0;
                    text-decoration: none;
                }

                a:hover {
                    text-decoration: underline;
                }

                a:focus,
                a:active {
                    border: 0;
                    outline: none;
                    box-shadow: unset;
                }
            }
        }
    }

    @include wide-mobile {
        .standard-footer .sub-footer-nav ul li {
            flex-grow: 1;
            width: 100%;
            margin-bottom: 15px;
        }
    }

    @include tablet {
        .standard-footer .sub-footer-nav ul li {
            width: 50%;
            text-align: left;
            margin-bottom: 15px;
        }
    }

    @include desktop {
        .standard-footer .sub-footer-nav ul li {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
                margin-left: 15px;
            }
        }
    }
}
</style>
