//////////////////////////////////////////////////////////////////////
// Routes for our Dashboard, and (local) routes for Content Site    //
//////////////////////////////////////////////////////////////////////

import { routeNameConstants, OIDC } from "@/constants.js";
import { lazyLoadDashboardLayout, lazyLoadContentHubLayout } from "@/router/router-helper.js";

const dashboardRoutes = [
    {
        path: "/dashboard/sso-redirect",
        name: routeNameConstants.SSO_REDIRECT,
        meta: { Title: 'Redirecting', requiresAuth: false}, 
        component: lazyLoadDashboardLayout("SsoRedirectLayout")
    },
    {
        path: "/dashboard/agency-admin-add",
        name: routeNameConstants.AGENCY_ADMIN_ADD,
        meta: { Title: 'Agency Admin Add', requiresAuth: true}, 
        component: lazyLoadDashboardLayout("AgencyAdminAddLayout")
    },
    {
        path: "/dashboard/find-my-agency",
        name: routeNameConstants.FIND_AGENCY_PAGE,
        meta: { title: 'Find My Agency!', requiresAuth: false},
        component: lazyLoadDashboardLayout("FindMyAgencyLayout")
    },
    {
        path: "/dashboard/select-agency",
        name: routeNameConstants.SELECT_AGENCY_PAGE,
        meta: { title: 'Select Agency', requiresAuth: false},
        component: lazyLoadDashboardLayout("SelectAgencyLayout")
    },
    {
        path: "/dashboard/agency-not-found",
        name: routeNameConstants.AGENCY_NOT_FOUND_PAGE,
        meta: { title: 'Agency Not Found', requiresAuth: false},
        component: lazyLoadDashboardLayout("AgencyNotFoundLayout")
    },
    {
        path: '/dashboard/login',
        name: routeNameConstants.LOGIN_SCREEN,
        meta: { title: 'Login', requiresAuth: false},
        component: lazyLoadDashboardLayout("LoginLayout")
    },
    {
        path: '/dashboard/forgotpassword',
        name: routeNameConstants.FORGOT_PASSWORD,
        meta: { title: 'Forgot Password', requiresAuth: false},
        component: lazyLoadDashboardLayout("ForgotPasswordLayout")
    },

    {
        path: '/dashboard/help',
        name: routeNameConstants.HELP,
        meta: { title: 'Help', requiresAuth: false},
        component: lazyLoadDashboardLayout("HelpLayout")
    },

    {
        path: '/dashboard',
        name: routeNameConstants.AGENT_DASHBOARD,
        meta: {title: 'Dashboard', requiresAuth: true},
        component: lazyLoadDashboardLayout("DashboardLayout")
    },
    {
        path: '/dashboard/manage-users',
        name: routeNameConstants.MANAGE_USERS,
        meta: {title: 'Manage Users', requiresAuth: true},
        component: lazyLoadDashboardLayout("ManageUsersLayout"),
    },
    {
        path: '/dashboard/manage-account',
        meta: {title: 'Manage My Account', requiresAuth: true},
        name: routeNameConstants.MANAGE_ACCOUNT,
        component: lazyLoadDashboardLayout("UserDetailsLayout"),
    },
    {
        path: '/dashboard/create-account',
        name: routeNameConstants.AGENT_REGISTRATION,
        meta: { title: 'Create Account', requiresAuth: false},
        component: lazyLoadDashboardLayout("AgentRegistrationLayout"),
    },
    {
        path: '/dashboard/create-new-password',
        name: routeNameConstants.CREATE_NEW_PASSWORD,
        meta: { title: 'Create New Password', requiresAuth: false},
        component: lazyLoadDashboardLayout("CreateNewPasswordLayout"),
    },
    {
        path: '/dashboard/file-claim',
        name: routeNameConstants.FILE_CLAIM,
        meta: {title: 'File a Claim', requiresAuth: true},
        get component() {
            return lazyLoadDashboardLayout("FileClaimLayout");
        }
    },
    {
        path: '/dashboard/create-CE-course',
        name: routeNameConstants.CREATE_CE_COURSES,
        meta: {title: 'Create CE Course', requiresAuth: true},
        get component() {
            return lazyLoadDashboardLayout("CreateCECourseLayout");
        }
    },
    {
        path: '/dashboard/course-registration',
        name: routeNameConstants.COURSE_REGISTRATION,
        meta: {title: 'Course-Registration', requiresAuth: true},
        component: lazyLoadDashboardLayout("EducationRegistrationLayout"),
    },
    {
        path: '/dashboard/course-registration-unauth',
        name: routeNameConstants.COURSE_REGISTRATION_UNAUTH,
        meta: {title: 'Course-Registration', requiresAuth: false},
        component: lazyLoadDashboardLayout("CourseRegistrationDetailsLayout"),
    },
    {
        path: '/dashboard/start-claim',
        name: routeNameConstants.START_CLAIM,
        meta: {title: 'Start a Claim', requiresAuth: true},
        get component() {
                return lazyLoadDashboardLayout("AppointmentOptionDetailsLayout");
        }
    },
    {
        path: '/dashboard/manage-claim',
        name: routeNameConstants.MANAGE_CLAIM,
        meta: {title: 'Manage Claim', requiresAuth: true},
        component: lazyLoadDashboardLayout("ManageClaimDetailsLayout"),
    },
    {
        path: '/dashboard/CECourseHome',
        name: routeNameConstants.CE_COURSES_HOME,
        meta: {title: 'CE Course Home', requiresAuth: false},
        component: lazyLoadDashboardLayout("CECourseLandingPage"),
    },
    {
        path: '/dashboard/manage-CEcourses',
        name: routeNameConstants.MANAGE_CECOURSES,
        meta: {title: 'Manage CE Courses', requiresAuth: true},
        component: lazyLoadDashboardLayout("ManageCECoursesLayout"),
    },
    {
        path: '/dashboard/CE-Course-Upcoming',
        name: routeNameConstants.CE_COURSES_UPCOMING,
        meta: {title: 'CE Course Home', requiresAuth: true, defaultTab: "UpCourses" },
        component: lazyLoadDashboardLayout("CECourseHomeLayout"),
    },
    {
        path: '/dashboard/CE-MyCourses',
        name: routeNameConstants.CE_MYCOURSES,
        meta: {title: 'CE Course Home', requiresAuth: true, defaultTab: "MyCourses" },
        component: lazyLoadDashboardLayout("CECourseHomeLayout"),
    },
    {
        path: '/dashboard/CE-Completed-MyCourses',
        name: routeNameConstants.CE_COMPLETED_MYCOURSES,
        meta: {title: 'CE Course Home', requiresAuth: true, defaultTab: "Complete" },
        component: lazyLoadDashboardLayout("CECourseHomeLayout"),
    },    
    {
        path: '/dashboard/work-order-details',
        meta: {title: 'Work Order Details', requiresAuth: true},
        name: routeNameConstants.WORDK_ORDER_DETAILS,
        component: lazyLoadDashboardLayout("WorkOrderDetailsLayout"),
    },
    {
        path: '/dashboard/order-survey-details',
        meta: {title: 'Survey Details', requiresAuth: true},
        name: routeNameConstants.ORDER_SURVEY_DETAILS,
        component: lazyLoadDashboardLayout("OrderSurveyDetailsLayout"),
    },
    {
        path: '/dashboard/user-details',
        meta: {title: 'User Details', requiresAuth: true},
        name: routeNameConstants.USER_DETAILS,
        component: lazyLoadDashboardLayout("UserDetailsLayout"),
    },
    {
        path: '/dashboard/registration-successful',
        name: routeNameConstants.AGENT_REGISTRATION_SUCCESSFUL,
        meta: { title: 'Registration Successful', requiresAuth: false},
        component: lazyLoadDashboardLayout("AgentRegistrationSuccessfulLayout"),
    },
    {
        path: '/dashboard/policy-holder-quote',
        meta: {title: 'Policy Holder Quote', requiresAuth: true},
        name: routeNameConstants.POLICY_HOLDER_QUOTE,
        component: lazyLoadDashboardLayout("PolicyHolderQuoteLayout"),
    },
    {
        path: '/dashboard/internal-admin-dashboard',
        meta: {title: 'Internal Admin Dashboard', requiresAuth: true},
        name: routeNameConstants.INTERNAL_ADMIN_DASHBOARD,
        redirect: {
            name: routeNameConstants.AGENT_DASHBOARD
        }
    },
    {
        path: '/dashboard/admin-agent-dashboard',
        meta: {title: 'Admin Dashboard', requiresAuth: true},
        name: routeNameConstants.ADMIN_AGENT_DASHBOARD,
        redirect: {
            name: routeNameConstants.AGENT_DASHBOARD
        }
    },
    {
        path: '/dashboard/appointment-option-details',
        meta: {title: 'Appointment Option Details', requiresAuth: true},
        name: routeNameConstants.APPOINTMENT_OPTION_DETAILS,
        component: lazyLoadDashboardLayout("AppointmentOptionDetailsLayout")
    },
    {
        path: '/dashboard/submit_claimdetails',
        meta: {title: 'Claim Details', requiresAuth: true},
        name: routeNameConstants.SUBMIT_CLAIM_REVIEW_DETAILS,
        component: lazyLoadDashboardLayout("SubmitClaimReviewDetailsLayout")
    },
    {
        path: '/dashboard/success_submit-claim',
        meta: {title: 'Success Submit Claim Details', requiresAuth: true},
        name: routeNameConstants.SUCCESS_SUBMIT_CLAIM,
        component: lazyLoadDashboardLayout("SuccessSubmitClaimLayout")  
    },

    {
        path: '/Healthcheck',
        meta: {title: 'SFA Health Check',requiresAuth: false},
        name: routeNameConstants.PRODUCTION_MONITORING,
        component: lazyLoadContentHubLayout("ProductionMonitoringPageLayout")
    },
    {
        path: '/*',
        name: routeNameConstants.NOT_FOUND_PAGE,
        meta: {requiresAuth: false},
        component: lazyLoadDashboardLayout("NotFoundPageLayout")
    }
    
 ]

const contentSiteRoutes = [

    {
        path: '/route-test-page',
        name: 'Route test page',
        component: lazyLoadContentHubLayout('HomePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/online-agent-claims-tools',
        name: 'Online agent claims tools',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/national-coverage',
        name: 'National coverage',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/honoring-national-safety-month',
        name: 'Honoring National Safety Month',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/tech-future',
        name: 'The insurance agents role in the future of vehicle safety systems',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/why-do-wiper-blades-matter',
        name: 'Why do wiper blades matter',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/oem',
        name: 'The ABCs of OEM and OE',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/5-things',
        name: '5 things you need to know about recalibration',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/sfa',
        name: 'Your new agent website is open for business',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/understand-recalibration',
        name: 'Help your policyholder understand recalibration',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/agency-care-desk',
        meta: {requiresAuth: false},
        name: routeNameConstants.CONTENT_SITE_AGENCY_CARE_DESK,
        component: lazyLoadContentHubLayout("AgentHelpDeskHomeLayout")
    },
    {
        path: '/tagged-articles',
        meta: {requiresAuth: false},
        name: routeNameConstants.CONTENT_SITE_TAGGED_ARTICLES_LIST,
        component: lazyLoadContentHubLayout('TaggedArticleDisplayListLayout')
    },
    {
        path: '/search',
        meta: {requiresAuth: false},
        name: routeNameConstants.CONTENT_SITE_SEARCH_RESULTS,
        component: lazyLoadContentHubLayout("SearchResultsLayout")
    },
    {
        path: '/articles/nationwide-auto-glass-service',
        name: 'Nationwide auto glass service',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/answers-you-need-when-you-need-them',
        name: 'Answers you need, when you need them',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/claims-best-practices',
        name: 'Delivering the best policyholder experience',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/understand-how-advanced-safety-systems-affect-recalibration-needs',
        name: 'Understand  how advanced safety systems affect recalibration needs',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/repairs-save-policyholders-cash',
        name: 'repairs save policyholders cash',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/a-day-in-the-life',
        name: 'A day In the life of a Safelite technician',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/catastrophes',
        name: 'Ready when and how you need us',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/recalibration-is-key-to-safety',
        name: 'Recalibration is key to safety',
        component: lazyLoadContentHubLayout('ArticlePageLayout'),
        meta: { requiresAuth: false },
    },
    {
        path: '/articles/nationwide-auto-glass-service',
        name: 'Nationwide auto glass service',
        meta: {requiresAuth: false},
        component: lazyLoadContentHubLayout("ArticlePageLayout")
    },  
    {
        path: '/articles/safelite-products',
        name: 'Adding even more value to your policyholder relationship',
        meta: {requiresAuth: false},
        component: lazyLoadContentHubLayout("ArticlePageLayout")
    },    
    {
        path: '/articles/drop-and-go-is-here',
        name: 'Drop and Go Is Here',
        meta: {requiresAuth: false},
        component: lazyLoadContentHubLayout("ArticlePageLayout")
    },   
    {
        path: '/',
        name: routeNameConstants.CONTENT_SITE_HOME,
        meta: {requiresAuth: false},
        component: lazyLoadContentHubLayout("HomePageLayout")
    },
     
]


export {
    dashboardRoutes,
    contentSiteRoutes
}